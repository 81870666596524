<template>
  <LoginDialogCard>
    <v-card-title> Восстановить пароль </v-card-title>
    <v-card-text>
      <div>
        Придумайте новый пароль для
        <span class="font-weight-bold">{{ maskedEmail }}</span
        >. Пароль должен быть не короче 8 знаков.
      </div>
      <v-text-field
        v-model="password1"
        :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword1 ? 'text' : 'password'"
        @click:append="showPassword1 = !showPassword1"
        label="Пароль"
        @input="onInput"
      ></v-text-field>
      <v-text-field
        v-model="password2"
        :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword2 ? 'text' : 'password'"
        @click:append="showPassword2 = !showPassword2"
        label="Повторите пароль"
        @input="onInput"
      ></v-text-field>
      <div class="d-flex align-center" style="min-height: 48px">
        <v-btn @click="onLogin" color="primary" class="mr-4"
          >Установить пароль</v-btn
        >
        <v-alert v-if="showAlert" dense outlined type="error" class="my-0">
          {{ alertMessage }}
        </v-alert>
      </div>
      <div v-if="showSuccess">
        Успешно! Сейчас перенапрявлю вас на старницу входа
      </div>
    </v-card-text>
  </LoginDialogCard>
</template>

<script>
import axios from 'axios'
import LoginDialogCard from '@/components/layout/LoginDialogCard.vue'

export default {
  components: {
    LoginDialogCard,
  },
  data() {
    return {
      open: true,
      token: null,
      maskedEmail: '',
      password1: '',
      password2: '',
      showPassword1: false,
      showPassword2: false,
      showAlert: false,
      alertMessage: '',
      showSuccess: false,
    }
  },
  mounted() {
    this.token = this.$route.params.token
    console.log('aff')
    axios
      .get(`/api/auth/get-user-info/${this.token}`)
      .then(res => {
        this.maskedEmail = res.data.email
      })
      .catch(() => {
        this.$router.push('/login')
      })
  },
  methods: {
    onInput() {
      this.showAlert = false
    },
    validateForm() {
      if (this.password1.length < 8) {
        this.alertMessage = 'Пароль не меньше 8 знаков'
        this.showAlert = true
        return false
      }
      if (this.password1 !== this.password2) {
        this.alertMessage = 'Пароли не совпадают'
        this.showAlert = true
        return false
      }
      return true
    },
    handleLoginError() {
      this.alertMessage = 'Что-то пошло не так'
      this.showAlert = true
    },
    handleLoginSuccess() {
      this.showSuccess = true
      setTimeout(() => this.$router.push(`/login`), 3000)
    },
    async onLogin() {
      if (!this.validateForm()) return
      try {
        await axios.put(`/api/auth/reset-password/${this.token}`, {
          password: this.password1,
        })
        this.handleLoginSuccess()
      } catch (err) {
        this.handleLoginError()
      }
    },
  },
}
</script>
